// Define the Roman-character translations for special-character urls
// Category names(left) paired with roman-character urls(right)
// This is used for categories, articles should already include urls

const urls = {
  'اكثر المواضيع قراءة': 'akthur-almawadie-qiraatan',
  'اسئلة شائعة': 'aisiilat-shayiea',
};

module.exports = {
  urls: urls,
};
